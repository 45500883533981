.nav {
  background: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  min-height: 90px;
  z-index: 99999;
}

.align {
  width: 100%;
  height: 100%;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.align img {
  height: 100%;
}

.nav .container .logo {
  width: 150px;
}

.nav .container .ml-auto .item {
  font-size: 20px;
  font-weight: bold;
  color: var(--color-blue-dark);
  margin-left: 20px;
  transition: 0.5s;
}

.nav .container .ml-auto .item .active {
  color: #000;
}

.nav .container .navbar-toggler {
  border-color: #fff;
  outline: none;
}

.nav .container .ml-auto .item:hover {
  color: #296cee;
}

@media (max-width: 700px) {
  .align .logo2,
  .align .logo3 {
    display: none !important;
  }

  .align {
    width: 50%;
    display: flex;
  }
}
