:root {
  font-size: 60%;
  --color-background: #F5F5F5;
  --color-blue: #4880EC;
  --color-blue-dark: #302D7E;
  --color-green: #00822D;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  
}

hr {
  margin: auto 120px;
}
h1,h2,h3,h4,h5 {
  color: var(--color-blue-dark);
}

html, body, #root {
  height: 100vh;
}

body {
  background: var(--color-background);
  margin-top: 100px;
  -webkit-font-smoothing: antialiased;
}

ul {
	list-style: none;
}

button {
  outline: none;
  border: none;
  border-radius: .8rem;
  font: 700 1.6rem Archivo;
  cursor: pointer;
}

.primary-button {
  width: 250px;
  height: 80px;
  align-self: center;
  background-color: #4880EC;
  color: #f5f5f5;
  outline: none;
  border: none;
  border-radius: .8rem;
  font: 700 1.6rem Archivo;
  transition: 0.2s;
  -moz-box-shadow: 0 2px 2em rgba(8,26,24,.1);
   -webkit-box-shadow: 0 2px 2em rgba(8,26,24,.1);
   box-shadow: 0 2px 2em rgba(8,26,24,.1);
}

.primary-button:hover {
  background-color: #3362be;
  -moz-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-box-shadow: 0 0 1px  rgba(0, 0, 0, 0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transform: translateY(1px);
}

input,
select,
textarea {
  outline: none;
}

a {
	text-decoration: none;
}

p {
  font: 400 1.6rem Archivo;
  color: #666666;
  text-align: justify;
  line-height: 20px;
}

hr {
  margin: 24px 0;
}

#root {
  display: flex;
  justify-content: center;
}

.title-text {
  font: 700 2.6rem Poppins;
}
span {
  color: var(--color-green);
}
.center {
  margin: 20px auto;
  display: flex;
  align-items: center;
  justify-content: center;
}


.conteudo {
  width: 100vw;
}

#load {
  width: 100px;
  height: auto;
}

@media (min-width: 700px) {
  :root {
    font-size: 62.5%;
  }
}